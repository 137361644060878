import React from 'react'
import autobind from 'class-autobind'
import styles from './index.module.css'
import { ReactComponent as Logo } from '../../images/logo.svg';

import GuyM from '../../images/guys-michael-halftone.png'
import GuyJ from '../../images/guys-james-halftone.png'

const colorWays = [
	{
	// 	background: 'rgb(255, 84, 82)',
	// 	foreground: 'rgb(241, 242, 228)',
	// }, {
	// 	background: 'rgb(1, 122, 255)',
	// 	foreground: 'rgb(255, 255, 255)',
	// }, {
	// 	background: 'rgb(251, 163, 1)',
	// 	foreground: 'rgb(247, 248, 249)',
	// }, {
	// 	background: 'rgb(69, 45, 124)',
	// 	foreground: 'rgb(241, 242, 228)',
	// }, {
	// 	background: 'rgb(0, 213, 227)',
	// 	foreground: 'rgb(241, 242, 228)',
	// }, {
	// 	background: 'rgb(0, 207, 130)',
	// 	foreground: 'rgb(247, 248, 249)',
	// }, {
	// 	background: 'rgb(247, 248, 249)',
	// 	foreground: 'rgb(1, 122, 255)',
	// }, {
		background: 'rgb(78, 183, 240)',
		foreground: 'rgb(247, 244, 237)',
	}, {
		background: 'rgb(254, 168, 144)',
		foreground: 'rgb(247, 244, 237)',
	}, {
		background: 'rgb(255, 123, 84)',
		foreground: 'rgb(247, 244, 237)',
	},
]
const colorWay = colorWays[Math.floor(Math.random() * colorWays.length)]
console.log('colorWay', colorWay);

class App extends React.Component {
	constructor(props) {
		super(props)
		autobind(this)

		this.state = {
			showVideo: false,
		}
	}

	toggleVideo() {
		this.setState({ showVideo: !this.state.showVideo })
	}

	render() {
		return (
			<main
				className={ styles.main }
				style={ { backgroundColor: colorWay.background, color: colorWay.foreground } }
			>
				<figure className={ styles['guy--james'] }><img src={ GuyJ } alt="Michael & James" /></figure>
				<figure className={ styles['guy--michael'] }><img src={ GuyM } alt="Michael & James" /></figure>
				<Logo
					className={ styles.logo }
					style={ { fill: colorWay.foreground } }
				/>
				<div
					className={ [styles.videoWall, this.state.showVideo && styles['videoWall--open']].join(' ') }
					onClick={ this.toggleVideo }
					role="button"
					tabIndex={ 0 }
				>
					{ this.state.showVideo &&
						<div className={ styles.videoAspectRatio }>
							<iframe
								allowFullScreen
								allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
								className={ styles.video }
								frameBorder="0"
								src="https://www.youtube.com/embed/y3qEacy1paA"
								title="James Ryan Stand-up"
							/>
						</div>
					}
				</div>
				<footer className={ styles.siteFooter }>
					<a
						className={ styles.button }
						href="https://www.youtube.com/channel/UCBeqJqedM5y2-OdHawrR67Q"
						style={ {
							backgroundColor: colorWay.background,
							boxShadow: `0 0 0 3px ${colorWay.foreground}`,
							color: colorWay.foreground,
						} }
					>Watch episodes on YouTube</a>
					<button
						className={ styles.button }
						onClick={ this.toggleVideo }
						style={ {
							backgroundColor: colorWay.background,
							boxShadow: `0 0 0 3px ${colorWay.foreground}`,
							color: colorWay.foreground,
						} }
					>
						Watch James at Carolines
					</button>
				</footer>
			</main>
		)
	}
}
export default App
