import React from 'react'
import ReactDOM from 'react-dom'
import registerServiceWorker from './registerServiceWorker'

import App from './00-pages/App'
import './index.css'

document.addEventListener('DOMContentLoaded', () => {
	ReactDOM.render(
		React.createElement(App),
		document.getElementById('root'),
	)
})

registerServiceWorker()
